import supportedLanguages from './supported-languages';
import defaultGlobalClientConfig, {
  IGlobalClientConfig,
} from '../../../default/config/GlobalClientConfig/GlobalClientConfig';
import {
  AuthenticationFlowTypes,
  FeaturedEventsCarouselType,
} from '../../../../config/Enums/App';
import Routes from '../../../../config/Routes/Routes';
import { MsisdnRequiredness } from '../../../../config/Enums/MsisdnRequiredness';

const GlobalClientConfig: IGlobalClientConfig = {
  ...defaultGlobalClientConfig,
  siteTitle: 'Orange Game Center',
  clientName: 'Orange Game Center',
  copyrightName: 'Swarmio Media',
  clientOrganizationSiteUrl: 'https://swarmio.media/',
  marketingSiteUrl: 'https://Orangegg.emberhub.gg',
  theme: 'tw-light',
  allowTokenLogin: true,
  storeOnly: true,
  nav: {
    orientation: 'hidden',
  },
  currency: {
    showOnlyPoints: false,
    hideCurrencySelectOnStore: true,
  },
  accountSetupRoute: Routes.connectGameAccounts,
  showStreamsOnHomePage: false,
  numberOfEventsToShowOnHomePage: 6,
  numberOfServersToShowOnHomePage: 2,
  authenticationFlowType: AuthenticationFlowTypes.Carousel,
  featuredEventsCarouselType: FeaturedEventsCarouselType.Centered,
  streams: {
    headerType: 'carousel',
  },
  internationalization: {
    defaultLng: 'en',
    supportedLngs: supportedLanguages,
  },
  eventDiscordLink: 'https://discord.gg/bTzKfHBUB5',
  msisdn: {
    format: 'TN',
    requiredness: MsisdnRequiredness.ABSENT,
  },
  subscriptions: {
    allowUpgrades: false,
    allowUnsubscribe: false,
  },
  showUpcomingMatchTimes: false,
  allowSocialSignIn: false,
  countryCode: 788,
  storeBanner: (storePartnertId: string | undefined) =>
    `https://store-assets.arenaesports.ae/store-banners/headers/${storePartnertId}/header-image.png`,
};

export default GlobalClientConfig;
