import supportedLanguages from './supported-languages';
import { ClientLanguage } from '../../../../services/LanguageService';
import {
  AuthenticationFlowTypes,
  FeaturedEventsCarouselType,
} from '../../../../config/Enums/App';
import { CountryCode } from 'libphonenumber-js';
import Routes from '../../../../config/Routes/Routes';
import { MsisdnRequiredness } from '../../../../config/Enums/MsisdnRequiredness';

export type SocialLinkTypes =
  | 'facebook'
  | 'twitter'
  | 'instagram'
  | 'youtube'
  | 'discord'
  | 'email';
export interface IGlobalClientConfig {
  siteTitle: string;
  clientName: string;
  copyrightName: string;
  partnerCopyrightName?: string;
  theme: 'tw-light' | 'tw-dark';
  clientOrganizationSiteUrl: string;
  marketingSiteUrl?: string;
  homeRedirectTo?: string;
  storeOnly?: boolean;
  socialLinks: { value: string; type: SocialLinkTypes; tooltip?: string }[];
  nav: {
    orientation: 'vertical' | 'horizontal' | 'hidden';
  };
  internationalization: {
    defaultLng: string;
    supportedLngs: ClientLanguage[];
  };
  msisdn: {
    format?: CountryCode;
    requiredness: MsisdnRequiredness;
  };
  allowTokenLogin: boolean;
  showStreamsOnHomePage: boolean;
  numberOfEventsToShowOnHomePage: number;
  numberOfServersToShowOnHomePage: number;
  authenticationFlowType: AuthenticationFlowTypes;
  featuredEventsCarouselType: FeaturedEventsCarouselType;
  accountSetupRoute: string;
  streams: {
    headerType: 'carousel' | 'standalone';
  };
  navigation: {
    eventCategories: boolean;
    hideGameServers: boolean;
    discordURL?: string;
  };
  currency?: {
    showPlusTaxLabel?: boolean;
    showOnlyPoints?: boolean;
    hidePoints?: boolean;
    hideCurrencySelectOnStore?: boolean;
  };
  eventDiscordLink?: string;
  subscriptions: {
    allowUpgrades: boolean; // not implemented for SLT
    allowUnsubscribe: boolean;
  };
  showUpcomingMatchTimes: boolean;
  allowSocialSignIn: boolean;
  countryCode: number;
  storeBanner: (storePartnerId: string | undefined) => string;
}

const GlobalClientConfig: IGlobalClientConfig = {
  siteTitle: 'Swarmio Hive',
  clientName: 'Swarmio',
  copyrightName: 'Swarmio Media',
  clientOrganizationSiteUrl: 'https://swarmio.media',
  accountSetupRoute: Routes.connectGameAccounts,
  theme: 'tw-dark',
  allowTokenLogin: false,
  socialLinks: [
    { type: 'facebook', value: 'https://www.facebook.com/swarmio.gg/' },
    { type: 'twitter', value: 'https://www.twitter.com/SwarmioGG/' },
    { type: 'youtube', value: 'https://www.youtube.com/swarmiotv/' },
    { type: 'email', value: 'mailto:contact@swarmio.media' },
  ],
  nav: {
    orientation: 'horizontal',
  },
  internationalization: {
    defaultLng: 'en',
    supportedLngs: supportedLanguages,
  },
  msisdn: {
    format: 'US',
    requiredness: MsisdnRequiredness.OPTIONAL,
  },
  showStreamsOnHomePage: true,
  numberOfEventsToShowOnHomePage: 3,
  numberOfServersToShowOnHomePage: 3,
  authenticationFlowType: AuthenticationFlowTypes.StandAlone,
  featuredEventsCarouselType: FeaturedEventsCarouselType.FullScreen,
  streams: {
    headerType: 'standalone',
  },
  navigation: {
    eventCategories: false,
    hideGameServers: true,
  },
  // Just something so that the banner appears when working on the default theme.
  eventDiscordLink: 'https://swarmio.media/',
  subscriptions: {
    allowUpgrades: true,
    allowUnsubscribe: true,
  },
  showUpcomingMatchTimes: true,
  allowSocialSignIn: true,
  countryCode: 1,
  storeBanner: (storePartnertId: string | undefined) =>
    `https://store-assets.arenaesports.ae/store-banners/headers/${storePartnertId}/header-image.png`,
};

export default GlobalClientConfig;
