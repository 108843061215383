/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
import { useStoreContext } from '../StoreContext';
import { formatNumberForLocale } from 'src/utilities/Utilities';
import { ISelectItemPaymentOption } from '../StoreActions';
import {
  MerchantPaymentOption,
  PriceOption,
  useMerchantPaymentOptions,
} from '../StoreHelpers';
import { useTranslation } from 'react-i18next';
import { StoreItemData } from '../useStoreReducer';
import { Fragment } from 'react';
import { useCatalogItemQuery } from 'src/generated/graphql';
import EnvVariables from 'src/config/EnvVariables/EnvVariables';
import { PaymentMethodImage } from '../PaymentMethodImage/PaymentMethodImage';
import GlobalClientConfig from 'HiveClient/config/GlobalClientConfig/GlobalClientConfig';

type StoreItemCurrencyOptionProps = {
  paymentMethod?: MerchantPaymentOption;
  priceOption: PriceOption;
  currencyName?: string;
};

const StoreItemCurrencyOption = ({
  paymentMethod,
  priceOption,
  currencyName,
}: StoreItemCurrencyOptionProps) => {
  const { state, dispatch } = useStoreContext();

  if (!paymentMethod) {
    return <Fragment></Fragment>;
  }

  return (
    <div
      key={paymentMethod.id}
      className={`tw-PaymentMethod tw-w-full tw-h-16 tw-px-4 tw-py-2
        tw-gap-4 tw-flex tw-flex-row tw-justify-between
        tw-rounded-2xl tw-border tw-border-solid tw-bg-zinc-900 hover:tw-cursor-pointer
        ${
          state.selectedPaymentOption &&
          state.selectedPaymentOption.method.id === paymentMethod.id
            ? 'tw-border-green-500'
            : 'tw-border-zinc-700'
        }`}
      onClick={() => {
        const selectedItem: ISelectItemPaymentOption = {
          type: 'SELECT_ITEM_PAYMENT_OPTION',
          payload: { option: priceOption, method: paymentMethod },
        };
        dispatch(selectedItem);
      }}
    >
      {/* payment method icon*/}
      <PaymentMethodImage paymentMethod={paymentMethod} />
      <div className='tw-PriceDisplay tw-place-self-center tw-text-zinc-100 tw-text-lg tw-font-bold tw-leading-snug tw-select-none'>
        {priceOption.currency === currencyName
          ? formatNumberForLocale(priceOption.price)
          : /* Currency display split for SH-3583 */
          paymentMethod.paymentMethodType === 'DCB' ||
            paymentMethod.paymentMethodType === 'QRCODE'
          ? priceOption.price.toLocaleString('en-US', {
              style: 'currency',
              currencyDisplay: 'symbol',
              currency: priceOption.currency,
              minimumFractionDigits: 2,
            })
          : priceOption.pspPrice.toLocaleString('en-US', {
              style: 'currency',
              currencyDisplay: 'symbol',
              currency: priceOption.currency,
              minimumFractionDigits: 2,
            })}
      </div>
    </div>
  );
};

type StoreMerchantPaymentOptionsForPriceProps = {
  itemId: string;
  priceOption: PriceOption;
  currencyName?: string;
};

const StoreMerchantPaymentOptionsForPrice = ({
  itemId,
  priceOption,
  currencyName,
}: StoreMerchantPaymentOptionsForPriceProps) => {
  const paymentOptions = useMerchantPaymentOptions({
    price: priceOption.price,
    currency: priceOption.currency,
  });

  const catalogItemQuery = useCatalogItemQuery({
    variables: {
      partnerId: EnvVariables.storePartnerId ?? '',
      itemId,
    },
  });

  const paymentChannels: string[] = catalogItemQuery.data?.catalogItem
    ?.availablePaymentChannels as string[];

  return (
    <Fragment>
      {paymentOptions
        ?.filter((method) => !method.disabled)
        .map((method: MerchantPaymentOption) => (
          <>
            {Array.isArray(paymentChannels) &&
            paymentChannels.includes(method.id.toString()) ? (
              <StoreItemCurrencyOption
                paymentMethod={method}
                priceOption={priceOption}
                currencyName={currencyName}
              />
            ) : null}
          </>
        ))}
    </Fragment>
  );
};

type StoreItemDetailCurrencyOptionsProps = {
  currencyName?: string;
  priceOptions?: PriceOption[];
  currentItem?: StoreItemData | null;
};

export const StoreItemDetailCurrencyOptions = ({
  priceOptions = [],
  currentItem,
  currencyName,
}: StoreItemDetailCurrencyOptionsProps) => {
  const { t } = useTranslation('store');

  return currentItem ? (
    <div className='tw-PaymentOptions tw-w-full  tw-flex-col tw-justify-start tw-items-start tw-gap-2 tw-inline-flex'>
      <div className='tw-SelectPaymentMethod tw-w-full tw-text-zinc-100 tw-text-lg tw-font-bold tw-leading-7'>
        {t('select-payment-method')}
      </div>
      <div className='tw-PaymentOptions tw-w-full tw-flex-col tw-justify-start tw-items-start tw-gap-2 tw-flex'>
        {priceOptions.map((option: PriceOption) =>
          option.isPointsPrice && !GlobalClientConfig.currency?.hidePoints ? ( // TODO: Confirm that there will be always only one option for points and one payment method
            <StoreItemCurrencyOption
              key={option.currencyId}
              paymentMethod={option.paymentOptions?.[0]}
              priceOption={option}
              currencyName={currencyName}
            />
          ) : (
            <StoreMerchantPaymentOptionsForPrice
              key={option.currencyId}
              priceOption={option}
              currencyName={currencyName}
              itemId={currentItem.itemId}
            />
          )
        )}
      </div>
    </div>
  ) : (
    <div>No valid item selected</div>
  );
};
