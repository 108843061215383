import Avatar, { AvatarSizes } from 'src/components/Avatar/Avatar';

import ImageTile, { Props as ImageTileProps } from './ImageTile';

import { faUser, faUsers } from 'HiveClient/components/Icons/Icons';
import React from 'react';

// omit image and label because we define them here for the ImageTile
export interface Props
  extends Omit<ImageTileProps, 'image' | 'label' | 'semantic'> {
  avatar?: string | null;
  name?: string;
  profileType: 'team' | 'user';
  avatarSize?: AvatarSizes;
  avatarGrayscale?: boolean;
  fullWidth?: boolean;
  href?: string;
  label?: React.ReactNode;
  responsive?: boolean;
  avatarClassName?: string;
  'data-cy'?: string;
}

const ProfileTile = (props: Props) => (
  <ImageTile
    className={props.className}
    id={props.id}
    href={props.href}
    onClick={props.onClick}
    isDisabled={props.isDisabled}
    isSelected={props.isSelected}
    fullWidth={props.fullWidth}
    semantic={props.profileType === 'team' ? 'team-profile' : 'user-profile'}
    image={
      <Avatar
        fullWidth={props.fullWidth}
        altText={props.name}
        className={[
          'text-white',
          ...(props.avatarGrayscale ? ['tw-grayscale'] : ['']),
          ...(props.responsive ? ['max-sm:tw-w-16'] : ['']),
          props.avatarClassName ?? '',
        ].join(' ')}
        fallbackIcon={props.profileType === 'team' ? faUsers : faUser}
        photo={props.avatar}
        size={props.avatarSize ?? AvatarSizes.lg}
      />
    }
    label={props.label ?? props.name ?? null}
    data-cy={props['data-cy']}
    responsive={props.responsive}
  />
);

export default ProfileTile;
